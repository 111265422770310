<ng-container *transloco="let t">
  <div class="container">
    <div class="error">
      <img src="/assets/svg/info-red.svg">
      <p>{{ formatError(t(localizationString)) }}</p>
    </div>
      <div>
          <a class="ok-button" (click)="close()">{{ t("generic.ok") }}</a>
      </div>
  </div>
</ng-container>