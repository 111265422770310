import { Injectable, ComponentFactoryResolver, ApplicationRef, Injector, Type, EmbeddedViewRef, ComponentRef, PLATFORM_ID, Inject } from '@angular/core';
import { DynamicDialogComponent } from './dynamic-dialog.component';
import { DynamicDialogInjector } from './dynamic-dialog-injector';
import { DynamicDialogConfig } from './dynamic-dialog-config';
import { DynamicDialogRef } from './dynamic-dialog-ref';
import { isPlatformBrowser } from '@angular/common';
import { DomHandler } from './dom-handler';
import { PlatformIdToken } from '../../utils/type-utils';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  private readonly dialogComponentRefMap: Map<DynamicDialogRef, ComponentRef<DynamicDialogComponent>> = new Map();

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
    @Inject(PLATFORM_ID) private platformId: PlatformIdToken
  ) { }

  open(componentType: Type<any>, config: DynamicDialogConfig): DynamicDialogRef {
    const dialogRef = this.appendDialogComponentToBody(config);
    const componentRef = this.dialogComponentRefMap.get(dialogRef);

    componentRef!.instance.childComponentType = componentType;
    return dialogRef;
  }

  private appendDialogComponentToBody(config: DynamicDialogConfig) {
    const map = new WeakMap();
    map.set(DynamicDialogConfig, config);

    const dialogRef = new DynamicDialogRef();
    map.set(DynamicDialogRef, dialogRef);

    const sub = dialogRef.onClose.subscribe(() => {
      const componentRef = this.dialogComponentRefMap.get(dialogRef);
      componentRef!.instance.close();
    });

    const destroySub = dialogRef.onDestroy.subscribe(() => {
      this.removeDialogComponentFromBody(dialogRef);
      destroySub.unsubscribe();
      sub.unsubscribe();
    });

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(DynamicDialogComponent);
    const componentRef = componentFactory.create(new DynamicDialogInjector(this.injector, map));

    this.appRef.attachView(componentRef.hostView);

    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    if(isPlatformBrowser(this.platformId)){
      document.body.appendChild(domElem);
    }

    this.dialogComponentRefMap.set(dialogRef, componentRef);
    return dialogRef;
  }

  private removeDialogComponentFromBody(dialogRef: DynamicDialogRef) {
    if(!dialogRef || !this.dialogComponentRefMap.has(dialogRef)) {
      return;
    }

    const componentRef = this.dialogComponentRefMap.get(dialogRef);
    this.appRef.detachView(componentRef!.hostView);
    componentRef!.destroy();
    this.dialogComponentRefMap.delete(dialogRef);
    DomHandler.releaseZIndex();
  }
}
