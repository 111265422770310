import { BehaviorSubject, Observable } from 'rxjs';
import { IllegalOperationException, isNotNullOrUndefined } from 'in-time-core';

export abstract class LoadingStateComponent {
  private loadingSnapshots: BehaviorSubject<boolean>;
  private _loading: boolean;

  constructor() {
    this._loading = false;
    this.loadingSnapshots = new BehaviorSubject<boolean>(this._loading);
  }

  get loading$(): Observable<boolean> {
    return this.loadingSnapshots;
  }

  get loading(): boolean {
    return this._loading;
  }

  onDestroy(): void {
    this.loadingSnapshots.complete();
  }

  async runWithLoadingScreen<T>(action: () => Promise<T>, onError?: () => T): Promise<T> {
    if(this._loading) {
      if(isNotNullOrUndefined(onError)) {
        return onError();
      }

      throw new IllegalOperationException('Operation is already in progress.');
    }

    this.changeState(true);
    const result = await action();
    this.changeState(false);

    return result;
  }

  private changeState(loading: boolean): void {
    this._loading = loading;
    this.loadingSnapshots.next(loading);
  }
}