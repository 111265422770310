import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: [ './loading-spinner.component.scss' ]
})
export class LoadingSpinnerComponent {
  @Input() pageLoading: boolean = false;
}