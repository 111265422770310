import {
  Directive, ElementRef, Input, HostBinding, Inject
} from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[drag-scroll-item]',
  standalone: true,
})
export class DragScrollItemDirective {
  @HostBinding('style.display') display = 'inline-block';

  _elementRef: ElementRef;
  _dragDisabled = false;

  @Input('drag-disabled')
  get dragDisabled() {
    return this._dragDisabled;
  }

  set dragDisabled(value: boolean) {
    this._dragDisabled = value;
  }

  constructor(@Inject(ElementRef) elementRef: ElementRef) {
    this._elementRef = elementRef;
  }
}
