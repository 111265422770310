<ng-container *transloco="let t">
  <div class="not-found">
    <div class="image-container">
      <img src="/assets/images/under-construction.png">
    </div>
    <div class="text-container">
      <h1>{{ t('pageNotFound.title') }}</h1>
      <p>{{ t('pageNotFound.description') }}</p>
    </div>
  </div>
</ng-container>