
export class DynamicDialogConfig {
  data?: any;
  closeOnEscape?: boolean;
  baseZIndex?: number;
  autoZIndex?: boolean;
  dismissableMask?: boolean;
  closable?: boolean;
  modal?: boolean;
}
