/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-mixed-spaces-and-tabs */
import { Component, Type, ComponentFactoryResolver, ViewChild, OnDestroy, ComponentRef, AfterViewInit, ChangeDetectorRef, Renderer2, NgZone, ElementRef, ChangeDetectionStrategy, ViewRef, PLATFORM_ID, Inject } from '@angular/core';
import { trigger,style,transition,animate,AnimationEvent, animation, useAnimation } from '@angular/animations';
import { DynamicDialogContentDirective } from './dynamic-dialog-content';
import { DynamicDialogConfig } from './dynamic-dialog-config';
import { DomHandler } from './dom-handler';
import { DynamicDialogRef } from './dynamic-dialog-ref';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { PlatformIdToken } from '../../utils/type-utils';

const showAnimation = animation([
  style({ transform: '{{transform}}', opacity: 0 }),
  animate('{{transition}}', style({ transform: 'none', opacity: 1 }))
]);

const hideAnimation = animation([
  animate('{{transition}}', style({ transform: '{{transform}}', opacity: 0 }))
]);

@Component({
  imports: [
    CommonModule,
    DynamicDialogContentDirective,
  ],
  standalone: true,
  selector: 'app-dynamic-dialog',
  templateUrl: './dynamic-dialog.component.html',
  animations: [
    trigger('animation', [
      transition('void => visible', [
        useAnimation(showAnimation)
      ]),
      transition('visible => void', [
        useAnimation(hideAnimation)
      ])
    ])
  ],
  changeDetection: ChangeDetectionStrategy.Default,
  styleUrls: ['./dynamic-dialog.component.scss']
})
export class DynamicDialogComponent implements AfterViewInit, OnDestroy {
  @ViewChild('mask') maskViewChild!: ElementRef;
	@ViewChild(DynamicDialogContentDirective) insertionPoint!: DynamicDialogContentDirective;

	visible: boolean = true;
	componentRef: ComponentRef<any> | null = null;
	mask: HTMLDivElement | null = null;
	childComponentType: Type<any> | null = null;
	container: HTMLDivElement | null = null;
	wrapper: HTMLElement | null = null;
	documentKeydownListener: any;
	documentEscapeListener: Function | null = null;
	maskClickListener: Function | null = null;
	transformOptions: string = 'scale(0.7)';

	constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private cd: ChangeDetectorRef,
    public renderer: Renderer2,
    public config: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    public zone: NgZone,
    @Inject(PLATFORM_ID) private platformId: PlatformIdToken
	) { }

	ngAfterViewInit() {
	  this.loadChildComponent(this.childComponentType);
	  this.cd.detectChanges();
	}

	loadChildComponent(componentType: Type<any> | null) {
	  if(!componentType) {
	    return;
	  }

	  const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);
	  const viewContainerRef = this.insertionPoint.viewContainerRef;
	  viewContainerRef.clear();

	  this.componentRef = viewContainerRef.createComponent(componentFactory);
	}

	moveOnTop() {
	  if(this.config.autoZIndex !== false) {
	    const zIndex = (this.config.baseZIndex || 0) + DomHandler.acquireZIndex();
	    if(this.container) {
	      this.container.style.zIndex = String(zIndex);
	    }
	    this.maskViewChild.nativeElement.style.zIndex = String(zIndex - 1);
	  }
	}

	onAnimationStart(event: AnimationEvent) {
	  switch(event.toState) {
	  case 'visible':
	    this.container = event.element;
	    this.wrapper = this.container?.parentElement ?? null;
	    this.moveOnTop();
	    this.bindGlobalListeners();

	    if(this.config.modal !== false) {
	      this.enableModality();
	    }

	    this.focus();
	    break;
	  case 'void':
	    this.onContainerDestroy();
	    break;
	  }
	}

	onAnimationEnd(event: AnimationEvent) {
	  if(event.toState === 'void') {
	    this.dialogRef.destroy();
	  }
	}

	onContainerDestroy() {
	  this.unbindGlobalListeners();
	  if(this.config.modal !== false) {
	    this.disableModality();
	  }
	  this.container = null;
	}

	close() {
	  this.visible = false;
	  this.cd.markForCheck();
	}

	hide() {
	  if(this.dialogRef) {
	    this.dialogRef.close();
	  }
	}

	enableModality() {
	  if(this.config.closable !== false && this.config.dismissableMask !== false) {
	    this.maskClickListener = this.renderer.listen(this.wrapper, 'mousedown', (event: any) => {
	      if(this.wrapper && this.wrapper.isSameNode(event.target)) {
	        this.hide();
	      }
	    });
	  }

	  if(this.config.modal !== false) {
	    if(isPlatformBrowser(this.platformId)){
	      DomHandler.addClass(document.body, 'p-overflow-hidden');
	    }
	  }
	}

	disableModality() {
	  if(this.wrapper) {
	    if(this.config.dismissableMask) {
	      this.unbindMaskClickListener();
	    }

	    if(this.config.modal !== false) {
	      if(isPlatformBrowser(this.platformId)){
	        const dialogList = DomHandler.find(document.body, 'app-dynamic-dialog');
	        if(dialogList.length == 1) {
	          DomHandler.removeClass(document.body, 'p-overflow-hidden');
	        }
	      }
	    }

	    if(!(this.cd as ViewRef).destroyed) {
	      this.cd.detectChanges();
	    }
	  }
	}

	onKeydown(event: KeyboardEvent) {
	  if(!this.container) {
	    return;
	  }

	  if(event.which === 9) {
	    event.preventDefault();

	    const focusableElements = DomHandler.getFocusableElements(this.container);
	    if(focusableElements && focusableElements.length > 0) {
	      if(!focusableElements[0].ownerDocument.activeElement) {
	        focusableElements[0].focus();
	      }
	      else {
	        const focusedIndex = focusableElements.indexOf(focusableElements[0].ownerDocument.activeElement);

	        if(event.shiftKey) {
	          if(focusedIndex === -1 || focusedIndex === 0)
	            focusableElements[focusableElements.length - 1].focus();
	          else
	            focusableElements[focusedIndex - 1].focus();
	        }
	        else {
	          if(focusedIndex === -1 || focusedIndex === (focusableElements.length - 1))
	            focusableElements[0].focus();
	          else
	            focusableElements[focusedIndex + 1].focus();
	        }
	      }
	    }
	  }
	}

	focus() {
	  if(this.container) {
	    const focusable = DomHandler.findSingle(this.container, '[autofocus]');
	    if(focusable) {
	      this.zone.runOutsideAngular(() => {
	        setTimeout(() => focusable.focus(), 5);
	      });
	    }
	  }
	}

	bindGlobalListeners() {
	  this.bindDocumentKeydownListener();
	  if(this.config.closable !== false && this.config.closeOnEscape !== false) {
	    this.bindDocumentEscapeListener();
	  }
	}

	unbindGlobalListeners() {
	  this.unbindDocumentKeydownListener();
	  this.unbindDocumentEscapeListener();
	}

	bindDocumentKeydownListener() {
	  this.zone.runOutsideAngular(() => {
	    this.documentKeydownListener = this.onKeydown.bind(this);
	    if(isPlatformBrowser(this.platformId)){
	      window.document.addEventListener('keydown', this.documentKeydownListener);
	    }
	  });
	}

	unbindDocumentKeydownListener() {
	  if(this.documentKeydownListener) {
	    if(isPlatformBrowser(this.platformId)){
	      window.document.removeEventListener('keydown', this.documentKeydownListener);
	      this.documentKeydownListener = null;
	    }
	  }
	}

	bindDocumentEscapeListener() {
	  const documentTarget: any = this.maskViewChild ? this.maskViewChild.nativeElement.ownerDocument : 'document';

	  this.documentEscapeListener = this.renderer.listen(documentTarget, 'keydown', (event) => {
	    if(event.which === 27 && this.container) {
	      if(parseInt(this.container.style.zIndex) === (DomHandler.zIndex + (this.config.baseZIndex ? this.config.baseZIndex : 0))) {
	        this.hide();
	      }
	    }
	  });
	}

	unbindDocumentEscapeListener() {
	  if(this.documentEscapeListener) {
	    this.documentEscapeListener();
	    this.documentEscapeListener = null;
	  }
	}

	unbindMaskClickListener() {
	  if(this.maskClickListener) {
	    this.maskClickListener();
	    this.maskClickListener = null;
	  }
	}

	ngOnDestroy() {
	  this.onContainerDestroy();
	  if(this.componentRef) {
	    this.componentRef.destroy();
	  }
	}
}
