<div #mask [ngClass]="{'dd-dialog-mask':true, 'dd-component-overlay': config.modal !== false}">
    <div *ngIf="visible" role="dialog"
        class="dd-dialog"
        [@animation]="{value: 'visible', params: {transform: transformOptions, transition: '150ms cubic-bezier(0, 0, 0.2, 1)'}}"
        (@animation.start)="onAnimationStart($event)"
        (@animation.done)="onAnimationEnd($event)">
        <div class="dd-dialog-content">
            <app-dynamic-dialog-content></app-dynamic-dialog-content>
        </div>
    </div>
</div>