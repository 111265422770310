/* eslint-disable max-len */
const BASE_Z_INDEX: number = 900;

export class DomHandler {
  private static _zIndex: number = BASE_Z_INDEX;

  static get zIndex(): number {
    return DomHandler._zIndex;
  }

  public static acquireZIndex(): number {
    return ++DomHandler._zIndex;
  }

  public static releaseZIndex(): void {
    DomHandler._zIndex--;
  }

  public static resetZIndex(): void {
    DomHandler._zIndex = BASE_Z_INDEX;
  }

  public static addClass(element: HTMLElement, className: string): void {
    if(element.classList) {
      element.classList.add(className);
    }
    else {
      element.className += ' ' + className;
    }
  }

  public static removeClass(element: HTMLElement, className: string): void {
    if(element.classList) {
      element.classList.remove(className);
    }
    else {
      element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }
  }

  public static find(element: HTMLElement, selector: string): any[] {
    return Array.from(element.querySelectorAll(selector));
  }

  public static findSingle(element: HTMLElement, selector: string): any {
    return element.querySelector(selector);
  }

  public static getFocusableElements(element: HTMLElement) {
    const focusableElements = DomHandler.find(element, `button:not([tabindex = "-1"]):not([disabled]):not([style*="display:none"]):not([hidden]),
                [href][clientHeight][clientWidth]:not([tabindex = "-1"]):not([disabled]):not([style*="display:none"]):not([hidden]),
                input:not([tabindex = "-1"]):not([disabled]):not([style*="display:none"]):not([hidden]), select:not([tabindex = "-1"]):not([disabled]):not([style*="display:none"]):not([hidden]),
                textarea:not([tabindex = "-1"]):not([disabled]):not([style*="display:none"]):not([hidden]), [tabIndex]:not([tabIndex = "-1"]):not([disabled]):not([style*="display:none"]):not([hidden]),
                [contenteditable]:not([tabIndex = "-1"]):not([disabled]):not([style*="display:none"]):not([hidden])`);

    if(typeof getComputedStyle === 'undefined') {
      return focusableElements;
    }

    const visibleFocusableElements = [];
    for(const focusableElement of focusableElements) {
      if(getComputedStyle(focusableElement).display != 'none' && getComputedStyle(focusableElement).visibility != 'hidden') {
        visibleFocusableElements.push(focusableElement);
      }
    }

    return visibleFocusableElements;
  }
}
