import { Component } from '@angular/core';
import { ErrorLike, isNotNullOrUndefined, isNullOrUndefined } from 'in-time-core';
import { DialogService } from '../dynamic-dialog/dialog.service';
import { DynamicDialogConfig } from '../dynamic-dialog/dynamic-dialog-config';
import { DynamicDialogRef } from '../dynamic-dialog/dynamic-dialog-ref';
import { TranslocoModule } from '@ngneat/transloco';

export function showErrorDialog(dialogService: DialogService, error: ErrorLike | null, test?: (err: ErrorLike) => boolean) {
  if(isNullOrUndefined(error) || (isNotNullOrUndefined(test) && !test(error))) {
    return;
  }

  dialogService.open(ErrorDialogComponent, {
    data: { 'error': error.toString() }
  });
}

@Component({
  standalone: true,
  imports: [ TranslocoModule ],
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent {
  readonly localizationString: string;
  readonly errorCode: string;

  constructor(
		public config: DynamicDialogConfig,
		private dialogRef: DynamicDialogRef,
  ) {
    const rawErrorCode = config.data.error as string;

    this.errorCode = rawErrorCode.split('/')[1];
    this.localizationString = rawErrorCode.replace('/', '.');
  }

  close(): void {
    this.dialogRef.close();
  }

  formatError(translation: string): string {
    if(translation == null || translation.length <= 0) {
      return this.errorCode;
    }
    else if(translation[translation.length - 1] == '.') {
      return `${translation} (${this.errorCode})`;
    }

    return `${translation}. (${this.errorCode})`;
  }
}
